import type { UtilityItem } from '@amzn/sitc-frontend/types';

const oopsUrl = 'https://m.media-amazon.com/images/G/01/backlot/assets/img/oops-sm.svg';

export const defaultErrorUserProfileUtility: UtilityItem = {
  primary: 'profile',
  key: 'profile',
  iconKey: 'AccountCircleOutlined',
  toolTip: 'universal_header.utility.profile.tooltip',
  navContents: [
    {
      key: 'user-details',
      navItems: [
        {
          avatarSource: oopsUrl,
          primary: 'universal_header.utility.profile.error.primary',
          secondary: 'universal_header.utility.profile.error.secondary',
          disabled: true,
        },
      ],
    },
  ],
};
