import React from 'react';
import ReactDOMClient from 'react-dom/client';
import singleSpaReact from 'single-spa-react';

import { ErrorBoundary } from './components/commons/ErrorBoundary';
import StandaloneRoot from './components/standalone/StandaloneRoot';

const lifecycles = singleSpaReact({
  React,
  ReactDOMClient,
  rootComponent: StandaloneRoot,
  errorBoundary: (error: Error) => <ErrorBoundary error={error} />,
});

export const { bootstrap, mount, unmount } = lifecycles;
