import { AppAlert } from '@amzn/sitc-frontend/components';
import React from 'react';

interface Props {
  header: React.ReactNode;
}

export const Base: React.FC<Props> = ({ header }) => (
  <>
    {header}
    <AppAlert />
  </>
);
