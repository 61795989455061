import type { MaybeUndefined } from '@amzn/sitc-frontend-types/helper';
import noop from 'lodash/noop';
import React, { useMemo, useState } from 'react';

import type { UniversalHeaderConfig } from '../types/universal-header';

type UniversalHeaderContextValue = {
  universalHeaderConfig: MaybeUndefined<UniversalHeaderConfig>;
  setUniversalHeaderConfig: React.Dispatch<MaybeUndefined<UniversalHeaderConfig>>;
};

const DefaultUniversalHeaderContextValue = {
  universalHeaderConfig: undefined,
  setUniversalHeaderConfig: noop,
};

interface UniversalHeaderProviderProps {
  children: React.ReactNode | React.ReactNode[];
}

export const UniversalHeaderContext = React.createContext<UniversalHeaderContextValue>(DefaultUniversalHeaderContextValue);

export const UniversalHeaderProvider: React.FC<UniversalHeaderProviderProps> = ({ children }) => {
  const [universalHeaderConfig, setUniversalHeaderConfig] = useState<MaybeUndefined<UniversalHeaderConfig>>(undefined);

  const value = useMemo(
    () => ({
      universalHeaderConfig,
      setUniversalHeaderConfig,
    }),
    [universalHeaderConfig]
  );

  return <UniversalHeaderContext.Provider value={value}>{children}</UniversalHeaderContext.Provider>;
};
