import type { AuthSession } from '@amzn/sitc-frontend/contexts';
import type { MaybeUndefined } from '@amzn/sitc-frontend-types/helper';
import type { AmazonPerson } from '@amzn/studios-domain-authority-java-script-clients/types/isdg';
import { useQuery } from '@apollo/client';
import React, { useMemo } from 'react';

import type { GetUserDataResponse } from '../queries/user';
import { buildUserQuery, getUserData } from '../queries/user';

const isdgClientId = 'STUDIOS_UNIVERSAL_HEADER_APP';

type UserDataContextValue = {
  userData: MaybeUndefined<AmazonPerson>;
  loading: boolean;
};

const DefaultUserDataContextValue = {
  userData: undefined,
  loading: false,
};

export interface UserDataProviderProps {
  children: React.ReactNode | React.ReactNode[];
  authSession: AuthSession;
}

export const UserDataContext = React.createContext<UserDataContextValue>(DefaultUserDataContextValue);

export const UserDataProvider: React.FC<UserDataProviderProps> = ({ children, authSession }) => {
  const { userId } = authSession;

  const { data, loading, error } = useQuery<GetUserDataResponse>(getUserData, {
    variables: {
      client: isdgClientId,
      queryJson: buildUserQuery(userId),
      pageSize: 1,
    },
  });

  const getAmazonPersonData = (): MaybeUndefined<AmazonPerson> => {
    if (error) {
      return undefined;
    }

    return data?.directory?.traversalSearchFromDocumentRoots?.schemaedData?.[0]?.out_AmazonPerson?.[0] || undefined;
  };

  const value = useMemo(
    () => ({
      userData: getAmazonPersonData(),
      loading,
    }),
    [loading]
  );

  return <UserDataContext.Provider value={value}>{children}</UserDataContext.Provider>;
};
