import type { UniversalHeaderProps } from '@amzn/sitc-frontend/components';
import { AppLogoSources } from '@amzn/sitc-frontend/constants';
import type { AppSwitcherContent, UtilityItem } from '@amzn/sitc-frontend/types';
import React from 'react';

import type { UniversalHeaderConfig } from '../types/universal-header';

export const BacklotLogo = () => <img src={AppLogoSources.backlot} alt="logo" />;

export const getUniversalHeaderProps = ({
  universalHeaderConfig,
  themeToggleEnabled,
  onThemeChange,
  defaultAppSwitcherContent,
  defaultUserProfileContent,
}: {
  universalHeaderConfig: UniversalHeaderConfig;
  themeToggleEnabled: boolean;
  onThemeChange: (theme: string) => void;
  defaultAppSwitcherContent: AppSwitcherContent[];
  defaultUserProfileContent: UtilityItem;
}): UniversalHeaderProps => {
  const { disableAppSwitcher, appSwitcherContent, disableUserProfileUtility, utilities, ...restUniversalHeaderProps } =
    universalHeaderConfig;

  let universalHeaderProps = { ...restUniversalHeaderProps };

  if (!universalHeaderProps.productIdentifier && !universalHeaderProps.logo) {
    universalHeaderProps = { ...universalHeaderProps, ...{ logo: <BacklotLogo /> } };
  }

  if (!disableAppSwitcher) {
    universalHeaderProps = { ...universalHeaderProps, ...{ appSwitcherContent: appSwitcherContent || defaultAppSwitcherContent } };
  }

  if (!disableUserProfileUtility) {
    universalHeaderProps = {
      ...universalHeaderProps,
      ...{ utilities: utilities ? [...utilities, defaultUserProfileContent] : [defaultUserProfileContent] },
    };
  }

  if (themeToggleEnabled) {
    universalHeaderProps = { ...universalHeaderProps, ...{ enableThemeSwitcher: themeToggleEnabled, onThemeChange } };
  }

  return universalHeaderProps;
};
