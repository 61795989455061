import type { SearchResult } from '@amzn/studios-origin-search-app-shared-types';
import React from 'react';

import { headerContent, headerLinkA, headerLinkB } from './headerLinks';
import { getAppSwitcherContent, getGroupedAppSwitcherContent } from './navContents';

export const getUniversalHeaderLogo = (stage: string): any => ({
  productIdentifier: 'Universal Header Demo - Logo Section',
  productIdentifierPath: `https://studios-universal-header.app.${stage}.frontend-platform.studios.a2z.com/`,
  logo: (
    <img src="https://m.media-amazon.com/images/G/01/backlot/assets/img/source-light.svg" alt="logo" style={{ margin: '0 2px 0 8px' }} />
  ),
});

export const getUniversalHeaderWithAppSwitcher = (stage: string): any => {
  const appSwitcherContent = getAppSwitcherContent(stage);
  return {
    productIdentifier: 'Universal Header Demo - App Switcher',
    appSwitcherContent,
  };
};

export const getUniversalHeaderWithGroupedAppSwitcher = (stage: string): any => {
  const appSwitcherContent = getGroupedAppSwitcherContent(stage);
  return {
    productIdentifier: 'Universal Header Demo - Grouped App Switcher',
    appSwitcherContent,
  };
};

export const getUniversalHeaderWithOriginSearch = () => ({
  originSearch: {
    enable: true,
    getSearchResultNavigatePath(searchResult: SearchResult) {
      const titleDocumentId = searchResult.documentIdentifier;
      return `/titles/${titleDocumentId}/update`;
    },
  },
});

export const universalHeaderWithHeaderLinks = {
  productIdentifier: 'Universal Header Demo - Header Links',
  headerLinks: [headerLinkA, headerLinkB, headerContent],
};
