import { AppSwitcherConfigs, BacklotApps } from '@amzn/sitc-frontend/constants';
import { useFeature } from '@amzn/sitc-frontend/hooks';
import type { AppSwitcherContent } from '@amzn/sitc-frontend/types';

export const useDefaultAppSwitcherContent = (): AppSwitcherContent[] => {
  const originViewAppEnabled = useFeature('enableOriginViewApp');
  const sourceLibraryAppEnabled = useFeature('enableSourceLibraryApp');

  return [
    {
      subheader: 'universal_header.appswitcher.backlot_apps.subheader',
      navItems: [
        AppSwitcherConfigs[BacklotApps.Almanac],
        AppSwitcherConfigs[BacklotApps.OriginAuthor],
        ...(originViewAppEnabled ? [AppSwitcherConfigs[BacklotApps.OriginView]] : []),
        AppSwitcherConfigs[BacklotApps.Release],
        ...(sourceLibraryAppEnabled ? [AppSwitcherConfigs[BacklotApps.SourceLibrary]] : []),
        AppSwitcherConfigs[BacklotApps.SourcePlan],
        AppSwitcherConfigs[BacklotApps.Mgm],
        AppSwitcherConfigs[BacklotApps.MgmLicensing],
        AppSwitcherConfigs[BacklotApps.Residuals],
        AppSwitcherConfigs[BacklotApps.DueDiligence],
        AppSwitcherConfigs[BacklotApps.LocalizationCentral],
        AppSwitcherConfigs[BacklotApps.StudiosApps],
        AppSwitcherConfigs[BacklotApps.Salesforce],
      ],
    },
    {
      subheader: 'universal_header.appswitcher.third_party_apps.subheader',
      navItems: [
        AppSwitcherConfigs[BacklotApps.Airtable],
        AppSwitcherConfigs[BacklotApps.Fabric],
        AppSwitcherConfigs[BacklotApps.Rightsline],
      ],
    },
  ];
};
