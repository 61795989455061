import { useContext } from 'react';

import { EventsContext } from '../contexts/Events';

export const useEventBus = () => {
  const eventBus = useContext(EventsContext);

  if (!eventBus) {
    throw new Error('You are missing your Events provider.');
  }
  return eventBus;
};
