import { StandaloneConfiguration } from '@amzn/sitc-frontend/components';
import type { MicroAppInitializationProps } from '@amzn/sitc-frontend/types';
import type { MicroAppRegistryInfo } from '@amzn/sitc-frontend-micro-app-sdk-core';
import React from 'react';

import { microAppName } from '../../configs/app';
import { UniversalHeaderShell } from '../UniversalHeaderShell';
import { StandaloneConfigOptions } from './StandaloneConfigOptions';

const StudiosUniversalHeaderApp = React.lazy(() => import('../StudiosUniversalHeaderApp'));

const configuration: Record<string, unknown> = {
  microAppName,
  featureOverrides: { enableUserProfileApp: true, enableThemeToggle: true },
};

const radioConfig: Record<'ownChannelNames' | 'channelNames', string[]> = {
  channelNames: ['origin-search', 'universal-header'],
  ownChannelNames: ['universal-header'],
};

const microApps: MicroAppRegistryInfo[] = [
  {
    microAppName: 'amzn-studios-origin-search-app',
    packageName: '@amzn/studios-origin-search-app',
    version: '*',
    accessLevel: 'restricted',
  },
];

const RootComponent: React.FC<MicroAppInitializationProps> = (props) => (
  <StudiosUniversalHeaderApp {...props}>
    <UniversalHeaderShell {...props} />
    <StandaloneConfigOptions {...props} />
  </StudiosUniversalHeaderApp>
);

const StandaloneRoot = () => (
  <StandaloneConfiguration
    configuration={configuration}
    microAppName={microAppName}
    microApps={microApps}
    radioConfig={radioConfig}
    RootComponent={RootComponent}
    userPoolMicroAppName="amzn-studios-demo-micro-app"
  />
);

export default StandaloneRoot;
