import type { UniversalHeaderProps } from '@amzn/sitc-frontend/components';
import { UniversalHeader } from '@amzn/sitc-frontend/components';
import { useMicroApp } from '@amzn/sitc-frontend/hooks';
import type { MicroAppInitializationProps, OriginSearchAppConfig } from '@amzn/sitc-frontend/types';
import type { Channel } from '@amzn/sitc-frontend-micro-app-sdk-core/radio';
import type { Filter, SearchResult } from '@amzn/studios-origin-search-app-shared-types';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SingleSpaContext } from 'single-spa-react';

interface Props extends MicroAppInitializationProps {
  getSearchResultNavigatePath: (searchResult: SearchResult) => string;
  originSearchFilters?: Filter[];
  universalHeaderProps: UniversalHeaderProps;
}

export const UniversalHeaderWithSearch: React.FC<Props> = ({
  getSearchResultNavigatePath,
  originSearchFilters,
  universalHeaderProps,
  ...props
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { appId, radio } = props;

  const searchRegistrationKey = `${appId}#header-origin-search`;
  const originSearchChannel = radio.getChannel('origin-search') as unknown as Channel;

  const onSearchResultClick = (searchResult: SearchResult): void => {
    const path = getSearchResultNavigatePath(searchResult);
    navigate(path);
  };

  useMicroApp<OriginSearchAppConfig>({
    loadProps: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      microAppContainer: () => document.querySelector('.header-origin-search-container')!,
      microAppInitializationProps: {
        ...props,
        registrationKey: searchRegistrationKey,
      },

      microAppName: 'amzn-studios-origin-search-app',
      singleSpaContext: SingleSpaContext,
    },
    registrationProps: {
      channel: originSearchChannel,
      registrationKey: searchRegistrationKey,
      registrationOptions: {
        appId,
        theme,
        getSearchConfiguration: () => ({
          renderOptions: {},
          searchOptions: {
            domains: ['TITLE'],
            eventHandlers: {
              onSearchResultClick: (searchResult: SearchResult) => onSearchResultClick(searchResult),
            },
            filters: originSearchFilters,
          },
        }),
      },
    },
  });

  return <UniversalHeader fixPosition enableOriginSearch {...universalHeaderProps} />;
};
